import Vue from 'vue'
import VueRouter from 'vue-router'



import Login from "@/views/Login.vue"
import Forgot from "@/views/Forgot.vue"
import store from "@/store";
const originalPush = VueRouter.prototype.push
  VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)
//静态路由
export const staticRoutes=[
	{
		path: '/',
		name: 'Login',
		component: Login
	},
	{
		path: '/forgot',
		name: 'Forgot',
		component: Forgot
	},
	// {
	// 	path: '*',
	// 	name: 'Notfound',
	// 	component: () => import('@/views/Notfound.vue')
 //    }
]
//动态路由 
export const activeRoutes=[
	{
		path: '/main',
		name: 'Main',
		meta:{title:"主页"},
		component: () => import('@/views/Main.vue'),
		children:[
			{
				path: '',
				name: 'Home',
				component: () => import('@/views/Home.vue'),
				meta:{icon:'el-icon-shouye',nameindex:'Home',title:"首页",isshow:true}
			} ,
			{
				path: '/kb',
				name: 'Kb',
				component: () => import('@/views/Kb.vue'),
				meta:{icon:"el-icon-jiankangketang",nameindex:'Kb',title:"课表",isshow:true},
				children:[
					{
						path: '',
						name: 'Allkc',
						component: () => import('@/views/Allkc.vue'),
						meta:{icon:"",nameindex:'Allkc',title:"全部课程表",isshow:true}
					},
					{
						path: '/kangyw',
						name: 'Kangyw',
						meta:{icon:'',nameindex:'Kangyw',title:"21天抗遗忘",isshow:true},
						component: () => import('@/views/Kangyw.vue'),
					},
					{
						path: '/kcxz',
						name: 'Kcxz',
						component: () => import('@/views/kb/Kcxz.vue'),
						meta:{icon:"",nameindex:'',title:"课程选择",isshow:false}
					},
					{
						path: '/shangke',
						name: 'Shangke',
						component: () => import('@/views/Shangke.vue'),
						meta:{icon:"",nameindex:'',title:"上课",isshow:false}
					},
					
					{
						path: '/testrecord',
						name: 'Testrecord',
						component: () => import('@/views/kb/Testrecord.vue'),
						meta:{icon:"",nameindex:'',title:"测试记录",isshow:false}
					},
					{
					  path: '/dayin',
					  name: 'Dayin',
					  component: () => import('@/views/kb/Dayin.vue'),
					  meta:{icon:"",nameindex:'Dayin',title:"打印",isshow:false},
					}
				]
			},
			{
				path: '/xkrecord',
				name: 'Xkrecord',
				meta:{icon:'el-icon-caiwuguanli-zhangdanguanli-zhangdanxiangqing',nameindex:'Xkrecord',title:"消课记录",isshow:true},
				component: () => import('@/views/Xkrecord.vue'),
			},
			{
				path: '/xueyaninfo',
				name: 'Xueyuaninfo',
				meta:{icon:'el-icon-jiaowuguanli',nameindex:'Xueyuaninfo',title:"学员信息",isshow:true},
				component: () => import('@/views/Xueyuaninfo.vue'),
			},
			{
				path: '/startsk',
				name: 'Startsk',
				component: () => import('@/views/Startsk.vue'),
				meta:{icon:"",title:"开始上课",isshow:false}
			},
			{
				path: '/xsdetails',
				name: 'Xsdetails',
				meta:{icon:'',nameindex:'Xueyuaninfo',title:"学生详情",isshow:false},
				component: () => import('@/views/xuesheng/Xsdetails.vue'),
			},
			{
				path: '/myinfo',
				name: 'Myinfo',
				meta:{icon:'el-icon-gerenxinxi',nameindex:'Myinfo',title:"个人信息",isshow:true},
				component: () => import('@/views/Myinfo.vue'),
			},
		]
	},
	// 上课流程 Testrecord
	{
		path: '/sktest',
		name: 'Sktest',
		component: () => import('@/views/kb/Sktest.vue'),
		meta:{icon:"",title:"测试课",isshow:false}
	},
	{
		path: '/zshike',
		name: 'Zshike',
		component: () => import('@/views/kb/Zshike.vue'),
		meta:{icon:"",title:"正式课",isshow:false}
	},
	{
		path: '/kqjiance',
		name: 'Kqjiance',
		component: () => import('@/views/kb/Kqjiance.vue'),
		meta:{icon:"",title:"课前检测",isshow:false}
	},
	{
		path: '/zsresult',
		name: 'Zsresult',
		component: () => import('@/views/kb/Zsresult.vue'),
		meta:{icon:"",title:"剪纸盒结束页面",isshow:false}
	},
	{
		path: '/jianzh',
		name: 'Jianzh',
		component: () => import('@/views/kb/Jianzh.vue'),
		meta:{icon:"",title:"剪纸盒",isshow:false}
	},
	{
		path: '/createkyw',
		name: 'Createkyw',
		component: () => import('@/views/kb/Createkyw.vue'),
		meta:{icon:"",title:"创建抗遗忘",isshow:false}
	},
	{
		path: '/xhtest',
		name: 'Xhtest',
		component: () => import('@/views/kb/Xhtest.vue'),
		meta:{icon:"",title:"学后测试",isshow:false}
	},
]

// const routes = [

// ]

const router = new VueRouter({
	mode: 'hash',
	base: process.env.BASE_URL,
	routes:staticRoutes
})

// 全局前置守卫
router.beforeEach((to, from, next) => {
	// console.log(to, from, next);
	if (to.name === "Login") {
		if (store.state.jltoken) { 
			next({ name:"Home"})
		}
		next();
	}
	else if(to.name=='Forgot'){
		next();
	} 
	else { 
		next();
		if (store.state.jltoken) {
			//获取角色对应的权限
			next();
		} else { 
			router.push({name:'Main'})
		}
	}
})



export default router



