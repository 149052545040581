/* 

路径管理：修改维护接口地址
对路径进行集中管理
path={login,company,dapart......}

*/
const mode = "development";
var host = "";

if (mode === 'development') {
    host = "http://120.55.64.145/lixin/api-pc/"
} else {
    host = "http://120.55.64.145/lixin/"
}

export default {
    // 登录
    login: host + 'sys/login',
    // 验证码
    getCode:host+'sys/getCode',
    // 短信验证码
    sendSms:host+'sendSms',
    // 修改密码
    editPwd:host+'jg/resetPassword',
	// 首页
	homeList: host + 'teacher/homePageClass',
	homeKyw: host + 'teacher/homePageKyw',
    // 所有课程
    allkc: host + 'teacher/classSchedule',
	// 选择课程
	kcList: host + 'teacher/kcList',
	// 测试题
	testKcList: host + 'teacher/testKcList',
	// 测试题提交
	testSubmit: host + 'teacher/testKcSubmit',
	// 备课
	wordList: host + 'teacher/wordList',
	// 上课
	startSk:host+'teacher/summary',
	// 复习
	review: host + 'teacher/review',
	// 复习提交
	reviewSubmit:host+'teacher/reviewSubmit',
	// 课前检测
	classTest: host + 'teacher/classTest',
	// 课前检测提交
	testKqSubmit:host+'teacher/testSubmit',
	// 单词识记
	getWords:host+'teacher/getWords',
	// 剪纸盒
	jianList:host+'teacher/jianzhiheWord',
	// 学后检测列表
	xhList:host+'teacher/schoolTestWord',
	// 学后检测提交
	xhSubmit:host+'teacher/answerSubmit',
	// 创建抗遗忘
	createKyw:host+'teacher/createKyw',
	// 编辑抗遗忘
	editKyw:host+'teacher/editKyw',
	// 删除抗遗忘
	delKyw:host+'teacher/deleteKyw',
	// 抗遗忘列表
	kywList:host+'teacher/listKyw',
	printKyw:host+'teacher/printKyw',
	// 消课记录
	xkList:host+'teacher/classRecord',
	// 学员信息
	studentInfo:host+'teacher/student',
	// 学员详情
	studentDetail:host+'teacher/studentDetail',
	// 个人信息
	myInfo:host+'teacher/info',
	// 修改信息
	editInfo:host+'teacher/editInfo',
	// 课程类型
	kcType:host+'jg/kcType',
	// 测试记录
	testRecord:host+'teacher/testRecord',
	// 结束上课
	finishClass:host+'teacher/finishClass',
	csBaogao:host+'teacher/testRepost'
}