<template>
	<div class="login-container flex ac jc">
		<div class="login">
			<el-form :model="loginData" :rules="rules" ref="loginData">
			    <el-form-item prop="password">
			    	<div class="flex ac top">
			    		<img class="tel" src="~@/assets/images/pwd.png" alt="" />
			    		<div class="f20 c4 fw">新密码</div>
			    	</div>
			    	<div class="con">
			    		<el-input :type="pwdShow?'text':'password'" v-model="loginData.password" placeholder="请输入新密码">
							<i slot="suffix" @click="pwdShow=!pwdShow" class="el-input__icon el-icon-view"></i>
						</el-input>
			    	</div>
			    </el-form-item>
			    <el-form-item prop="surPassword">
			    	<div class="flex ac top">
			    		<img class="tel" src="~@/assets/images/pwd.png" alt="" />
			    		<div class="f20 c4 fw">确认密码</div>
			    	</div>
			    	<div class="con">
			    		<el-input :type="surpwdShow?'text':'password'" v-model="loginData.surPassword" placeholder="请输入确认密码">
							 <i slot="suffix" @click="surpwdShow=!surpwdShow" class="el-input__icon el-icon-view"></i>
						</el-input>
			    	</div>
			    </el-form-item>
			    <el-form-item prop="phone">
			    	<div class="flex ac top">
			    		<img class="tel" src="~@/assets/images/tel.png" alt="" />
			    		<div class="f20 c4 fw">手机号</div>
			    	</div>
			        <div class="con">
			    		<el-input max="11" v-model="loginData.phone" placeholder="请输入手机号"></el-input>
			    	</div>
			    </el-form-item>
				<el-form-item prop="code">
					<div class="flex ac top">
						<img class="tel" src="~@/assets/images/code.png" alt="" />
						<div class="f20 c4 fw">验证码</div>
					</div>
					  <div class="ms20">
              <el-input v-model="loginData.code" max="4" placeholder="请输入验证码">
                <el-button slot="suffix" class="code-btn" :disabled="disabled" @click="getCode">
                    <span class="Time">{{btnTxt}}</span>
                </el-button>
              </el-input>
<!-- 					    <el-row :gutter="10">
					        <el-col :span="18">
					          <el-input v-model="loginData.code" max="4" placeholder="请输入验证码"></el-input>
					        </el-col>
					        <el-col :span="6">
					          <el-image @click="getCaptcha" :src="captchaPath" style="height:60px"></el-image>
					        </el-col>
					    </el-row> -->
					</div>
				</el-form-item>
			    <el-form-item>
			        <div class="btn f20 cf fw flex ac jc" @click="submitForm('loginData')">确认修改</div>
			    </el-form-item>
			</el-form>
		</div>
	</div>
</template>

<script>
export default {
    data(){
        var validatePass2 = (rule, value, callback) => {
          if (value === '') {
            callback(new Error('请再次输入密码'));
          } else if (value !== this.loginData.password) {
            callback(new Error('两次输入密码不一致!'));
          } else {
            callback();
          }
        };
        const validatePhone = (rule, value, callback) => {
          // 判断输入框中是否输入手机号
          if (!value) {
            callback(new Error('手机号不能为空'))
          }
          //正则表达式进行验证手机号，从1开始，第二位是35789中的任意一位，以9数字结尾
          if (!/^1[35789]\d{9}$/.test(value)) {
            callback(new Error('手机号格式不正确'))
          }
          callback()
        };
        return {
          surpwdShow:false,
          pwdShow:false,
          btnTxt:'获取验证码',
          disabled:false,
          time:0,
          loginData:{
              phone:"",
              password:"",
              code:"",
              surPassword:""
          },
          rules:{
              phone:[
                   { required: true, trigger: 'blur', min: 11, max: 11, message: '长度不符合'},
                   {
                       validator:validatePhone,
                       trigger:"blur"
                   }
              ],
              password:[
                  {
                      required:true,
                      message:"请输入密码",
                      trigger:"blur"
                  },
                  {
                      validator:validatePass2,
                      trigger:"blur"
                  }
              ],
              surPassword:[
                  {
                      validator:validatePass2,
                      trigger:"blur"
                  }
              ],
              code:[
                  {
                      required:true,
                      message:"请输入验证码",
                      trigger:"blur"
                  }
              ]
          }
        }
    },
    mounted(){
      // this.getCaptcha()
    },
    methods: {
      // 获取验证码
        getCaptcha () {
          console.log('请求')
          this.loginData.code = ''
          this.api.login.getCode().then(res=>{
            console.log(res,'验证码')
             // this.captchaPath = window.URL.createObjectURL(res);
             this.captchaPath='data:image/png;base64,'+res.base64
             this.code=res.code
          })
        },
        submitForm(loginData) {
            this.$refs[loginData].validate((valid) => {
                if (valid) {
                  console.log(valid)
                  // return
                    let data=JSON.parse(JSON.stringify(this.loginData))
                    delete data.surPassword
                    this.api.login.editPwd(data).then(res=>{
                        // console.log(res);
                        if(res.result==0){
                            // 存储token、data
                            this.$store.commit("setToken1","");
                            this.$store.commit("SET_ROUTES",[]);
                            this.$store.commit("setUserData1",{});
                            this.$router.push({ name: 'Login' });
                            this.$message({
                                message: '修改成功',
                                type: 'success'
                            });
                            location.reload();

                        }
                    })

                } else {
                    this.$message({
                        message: '修改失败，请稍后重试～',
                        type: 'error'
                    })
                    return false;
                }
            });
        },
        resetForm(loginData) {
            this.$refs[loginData].resetFields();
        },
        getCode() {
          // 校验手机号码
          if (!this.loginData.phone) {
            //号码校验不通过
            this.$message({message: '请输入手机号',type:'warning'});
          } else if (!/1[35789]\d{9}/.test(this.loginData.phone)) {
            this.$message({message: '手机号格式不正确',type:'warning'});
          } else {
            this.time = 60;
            this.disabled = true;
            //调用倒计时方法
            this.timer();
            return
            let timestamp=new Date().valueOf()
            let data = {
            	phone: this.loginData.phone,
            	sign:this.$md5(timestamp+'lixinkeji'),
            	timestamp:timestamp
            }
            this.api.login.sendSms(data).then(res=>{
              console.log(res,'验证码')
               if(res.result==0){
                 this.time = 60;
                 this.disabled = true;
                 //调用倒计时方法
                 this.timer();
               }else{
                 this.$message.error('发送失败')
               }
            })
          }
        },
        // 倒计时方法
        timer() {
          if (this.time > 0) {
            this.time--;
            // console.log(this.time);
            this.btnTxt = this.time + "s后重新获取验证码";
            setTimeout(this.timer, 1000);
          } else {
            this.time = 0;
            this.btnTxt = "获取验证码";
            this.disabled = false;
          }
        },
    }
}
</script>

<style lang="less" scoped>
.login-container{
	height: 100vh;
	.login{
		width: 700px;
		.el-form{
			.tel{
				width: 25px;
				height: 31px;
				margin-right: 25px;
			}
			.con{
				background: #F7F8FA;
				padding: 0 20px;
				border-radius: 30px;
			}
			.el-input__icon{
				font-size: 18px;
			}
		}
	}
  .code-btn,.code-btn:hover,.code-btn:focus{
    background: transparent;
    height: 100%;
    border: none;
  }
}
</style>
