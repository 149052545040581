import Vue from 'vue'
import VueRouter from 'vue-router'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"
import {staticRoutes,activeRoutes} from "@/router/index.js";

import api from '@/api'

Vue.use(Vuex)
Vue.use(VueRouter)

const store = new Vuex.Store({

	// 数据
	state: {
		//路由
		routes: [],
        addRoutes: [],
		host: 'http://120.55.64.145/lixin/',
		action:'http://120.55.64.145/lixin/api/uploadFile',
		jltoken: "",
		userData: {},
		regioData: [],// 区域数据
		classData: [
			{name:'未开始',id:0},
			{name:'进行中',id:1},
			{name:'已完成',id:2},
			{name:'已过期',id:3},
		],// 
		ksType:['30分钟','60分钟','测试课'],
		kechengData:[],//课程类型
		pageSizes: [10, 20, 50, 100],
		defaultPageSize:10,
		history:[],
		shangkeData:{}
	},

	// 修改state
	mutations: {
		//路由
		SET_ROUTES(state,addRoutes){
            state.addRoutes = addRoutes;
            state.routes = staticRoutes.concat(addRoutes);
        },
		setSkData(state, data) {
			state.shangkeData = data;
		},
		//token
		setToken1(state,token) { 
			state.jltoken = token;
		},
		setUserData1(state, data) { 
			// consoe.log('更新用户信息',data)
			state.userData = data;
		},
		// 区域管理
		setKcData(state, data) { 
			state.kechengData = data;
		},
		// 历史记录
		setHistory(state, data) { 
			console.log(state,data,'历史记录')
			// state.history=[]
			state.history.push(data);
		},
		// 历史记录
		delHistory(state, data) { 
			state.history.pop()
			console.log(state.history.pop(),'历史记录1111')
			// state.history.push(data);
		},
	},
	
	actions: {
		//路由  activeRoutes
		setRouterAsync({commit}){
			var routes=activeRoutes;
			routes[0].children=activeRoutes[0].children
			commit("SET_ROUTES",routes);
		},
		// 区域管理
		setKcDataAsync({ commit }) { 
			api.login.kcType().then(res => { 
				if (res.result == 0) {
					commit("setKcData",res.dataList)
				}
			})
		},
		// 用户信息
		setUserInfo({ commit }) {
			api.kc.myInfo().then(res => {
		    console.log(res,'用户信息',res)
				if (res.result == 0) {
					console.log(res,'用户信息11111111')
					commit("setUserData1", res.data)
				}
			})
		},

	},
	
	modules: {
		
	},

	// 计算
	getter: {
      
	},
	
	plugins: [
		createPersistedState({
			reducer(state) { 
				return {
					jltoken: state.jltoken,
					userData: state.userData
				}
			}
		})
	]
})
export default store;
