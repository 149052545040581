/* 

总入口：集中所有模块，放置到一个对象中，统一访问
api ＝ {模块1，模块2，模块3......，模块n}

*/
// 登录
import login from "./login";
// 图片上传
import upload from "./upload"; 
// 所有课程
import kc from "./kc"; 

export default {
    // 登录
    login,
    // 图片上传
    upload,
	// 所有课程
	kc
}

