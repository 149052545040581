<template>
	<div id="app">
		<router-view />
	</div>
</template>
<script>
export default {
	mounted(){
		if(this.$route.name!=='Login'&&this.$route.name!=='Forgot'){
			if(this.$store.state.addRoutes.length>0){
				this.$router.options.routes=this.$store.state.routes;
				// this.$router.addRoutes(this.$store.state.addRoutes);
			}else{
				this.$store.dispatch('setRouterAsync').then(()=>{
					this.$router.options.routes=this.$store.state.routes;
					this.$router.addRoutes(this.$store.state.addRoutes);
				})
			}
		}
	},
	updated(){
		if(this.$route.name!=='Login'&&this.$route.name!=='Forgot'){
			if(this.$store.state.addRoutes.length>0){
				this.$router.options.routes=this.$store.state.routes;
				// this.$router.addRoutes(this.$store.state.addRoutes);
			}else{
				this.$store.dispatch('setRouterAsync').then(()=>{
					this.$router.options.routes=this.$store.state.routes;
					this.$router.addRoutes(this.$store.state.addRoutes);
				})
			}
		}
	}
}
</script>

<style lang="less">
html{
	font-size: 16px;
}
</style>
