import path from "./path"
import axios from "./axios"

export default {
    login(data) {
        return axios.post(path.login, data);
    },
    editPwd(data) {
        return axios.post(path.editPwd, data);
    },
    sendSms(data) {
        return axios.post(path.sendSms, data);
    },
    getCode(term={}) {
        return axios.get(path.getCode);
    },
	kcType(data){
	    return axios.post(path.kcType, data)
	},
	csBaogao(data) {
        return axios.post(path.csBaogao, data);
    },
}