/**
 * 什么情况下需要多个 axios 实例？
 *      当有多种配置的情况，可以创建多个 axios 实例
 */


// 整体配置、处理
import axios from "axios";

import { Message } from "element-ui";
// import { Loading } from 'element-ui';

import router from "../router";

import store from "../store";


// axios.defaults.headers.common['token'] = localStorage.getItem("token");
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
// axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded; charset=utf-8'

const instance = axios.create();
// let loadingInstance = null;
// 添加请求拦截器，给当前实例的那个请求添加拦截器
instance.interceptors.request.use(function (config) {
    console.log(config,'config')
    if (store.state.jltoken) {
        config.headers.token = store.state.jltoken;
        // config.headers.cookie='wolfking.jeeplus.session.id=a3dcf49f-8f19-4457-a5ad-8d491ea13d70;code=yfm5'
    }
    // 在发送请求之前做些什么
    return config;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});


// 给当前实例添加响应拦截器
instance.interceptors.response.use(function (response) {
    // loadingInstance.close();
    // 业务状态码：404，401 情况，进行全局处理
    // console.log(response)
    if (response.data.result == 404||response.data.code == 404) {
        Message({
            type: "error",
            message: response.data.resultNote,
            onClose() {
                // 移除无用数据
                store.commit("setToken1", "");
                store.commit("setUserData1", {});
                // 使用Vue路由实例
                router.push({ name: 'Login' })
            }
        })

        return false;
    }
    if (response.data.result == 401||response.data.code == 401) {
        Message({
            type: "error",
            message: response.data.resultNote,
            onClose() {

                // 移除无用数据
                store.commit("setToken1", "");
                store.commit("setUserData1", {});

                // 使用Vue路由实例
                router.push({ name: 'Login' })
            }
        })

        return false;
    }
    // 对响应数据做点什么
    return response.data;
}, function (error) {
        if (error && error.response) {
            switch (error.response.status) {
                case 400: error.message = '请求错误(400)'; break;
                case 401: error.message = '未授权，请重新登录(401)'; break;
                case 403: error.message = '拒绝访问(403)'; break;
                case 404: error.message = '请求出错(404)'; break;
                case 408: error.message = '请求超时(408)'; break;
                case 500: error.message = '服务器错误(500)'; break;
                case 501: error.message = '服务未实现(501)'; break;
                case 502: error.message = '网络错误(502)'; break;
                case 503: error.message = '服务不可用(503)'; break;
                case 504: error.message = '网络超时(504)'; break;
                case 505: error.message = 'HTTP版本不受支持(505)'; break;
                default: error.message = `连接出错(${error.response.status})!`;
            }
        } else {
            error.message = '连接服务器失败!'
        }
        Message.error(error.message);
    // 对响应错误做点什么
    return Promise.reject(error);
});

export default instance;
